import { useAuth } from '@/app/providers/auth-provider';
import { useEffect, useMemo, useState } from 'react';

import { ModalPurchase } from '@/components/modal-purchase';
import { UserRoleType } from '@/types/types.generated';
import { useTranslation } from 'react-i18next';
import type { IconType } from 'react-icons';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaGraduationCap } from 'react-icons/fa6';
import { GoHomeFill } from 'react-icons/go';
import { IoLogoUsd } from 'react-icons/io5';
import { MdEventNote, MdLibraryBooks, MdOutlineShoppingBag } from 'react-icons/md';
import { TbInfoCircle } from 'react-icons/tb';

export interface MentorNavLink {
  label: string;
  link: string;
  notificationType?: string;
  icon: IconType;
  type: undefined;
}

export interface StudentNavLink {
  label: string;
  link: string;
  type?: 'trial' | 'modal' | 'external';
  modal?: JSX.Element;
  icon: IconType;
  notificationType: undefined;
  newTab?: boolean;
}

export const useMenuList = () => {
  const { user, currentStudentId, mentorId } = useAuth();
  const [_, i18n] = useTranslation();
  const [navLinks, setNavLinks] = useState<MentorNavLink[] | StudentNavLink[]>([]);

  const guidebookLink = useMemo(
    () =>
      i18n.language === 'kr'
        ? 'https://doc.clickup.com/9014152611/d/h/8cmj3d3-11194/bf6b07b2401e7a4'
        : i18n.language === 'en'
          ? 'https://doc.clickup.com/9014152611/p/h/8cmj3d3-43194/7edd22cd87c09a6'
          : 'https://doc.clickup.com/9014152611/p/h/8cmj3d3-43714/19f869afc238c20',
    [i18n?.language],
  );

  const mentorNavLinks: MentorNavLink[] = useMemo(
    () => [
      {
        label: 'manage_appointments',
        link: `/mentor/${mentorId}/manage-appointments`,
        icon: GoHomeFill,
        type: undefined,
      },
      {
        label: 'lessons',
        link: `/mentor/${mentorId}/lesson-calendar`,
        notificationType: 'check_requests', //for notifications filter
        icon: FaRegCalendarAlt,
        type: undefined,
      },
      {
        label: 'my_availability',
        link: `/mentor/${mentorId}/availability`,
        icon: FaRegCalendarAlt,
        type: undefined,
      },
      {
        label: 'curriculum',
        link: 'https://naonow.learnworlds.com/home-mentors',
        icon: MdLibraryBooks,
        type: undefined,
      },
      {
        label: 'Payment',
        link: `/mentor/${mentorId}/payments`,
        icon: IoLogoUsd,
        type: undefined,
      },
    ],
    [mentorId],
  );

  const studentNavLinks: StudentNavLink[] = useMemo(
    () => [
      {
        label: 'dashboard',
        link: `/student/${currentStudentId}/manage-lessons`,
        icon: GoHomeFill,
        notificationType: undefined,
      },
      {
        label: 'lessons',
        link: `/student/${currentStudentId}/lesson-calendar`,
        icon: MdEventNote,
        notificationType: undefined,
      },
      {
        label: 'mentors',
        link: `/student/${currentStudentId}/mentors-list`,
        icon: FaGraduationCap,
        type: 'trial',
        modal: <ModalPurchase />,
        notificationType: undefined,
      },
      {
        label: 'purchase',
        link: `/student/${currentStudentId}/subscriptions`,
        icon: MdOutlineShoppingBag,
        notificationType: undefined,
      },
      // {
      //   label: 'free_classes',
      //   link: '#',
      //   icon: IoGiftOutline,
      //   type: 'modal',
      //   modal: <Info />,
      //   notificationType: undefined,
      // },
      {
        label: 'class_material',
        link: user?.phoneNumber?.startsWith('+82')
          ? 'https://naonow.learnworlds.com/home-kr'
          : user?.phoneNumber?.startsWith('+886')
            ? 'https://naonow.learnworlds.com/home-tw'
            : 'https://naonow.learnworlds.com/home',
        icon: MdLibraryBooks,
        type: 'external',
        notificationType: undefined,
      },
      {
        label: 'guidebook',
        link: guidebookLink,
        icon: TbInfoCircle,
        type: 'external',
        newTab: true,
        notificationType: undefined,
      },
    ],
    [user, guidebookLink, currentStudentId],
  );

  useEffect(() => {
    if (user?.role === UserRoleType.Mentor) {
      setNavLinks(mentorNavLinks);
    } else if (user?.role === UserRoleType.Student) {
      setNavLinks(studentNavLinks);
    }
  }, [user, guidebookLink, currentStudentId, mentorId]);

  return navLinks;
};
