import { AuthContext } from '@/app/providers/auth-provider/lib/auth-context';
import { useNotifications } from '@/app/providers/notification-provider';
import { INVITE_SET_PASSWORD_MUTATION, ME_QUERY } from '@/shared/apollo/graphql';
import { UPDATE_USER } from '@/shared/apollo/mutations/user/update-user';
import notify from '@/shared/utils/notify';
// import useLocalStorage from '@/shared/utils/use-local-storage';
import { type AuthStudent, type AuthenticatedUser, UserRoleType } from '@/types/types.generated';
import { useMutation, useQuery } from '@apollo/client';
import { type ReactNode, useEffect, useState } from 'react';

interface MeQueryData {
  authenticatedUser: AuthenticatedUser;
}

export interface InviteSetPasswordMutationData {
  redeemInvitePasswordSetToken: { [key: string]: unknown };
}

interface InviteSetPasswordMutationVars {
  email: string;
  token: string;
  password: string;
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { getAllNotifications } = useNotifications();
  const [currentStudent, setCurrentStudent] = useState<AuthStudent | null>(null);
  const [mentorId, setMentorId] = useState<string | undefined>();

  const [updateUser] = useMutation(UPDATE_USER);

  const {
    data: user,
    loading,
    refetch: refetchUser,
  } = useQuery<MeQueryData>(ME_QUERY, {
    variables: {
      studentId: localStorage.getItem('studentId') || '',
    },
    onCompleted: (data) => {
      const student = data?.authenticatedUser?.students?.find(
        (student) => student?.id === localStorage.getItem('studentId') || '',
      );

      const lsMentorId = localStorage.getItem('mentorId');
      if (!lsMentorId || lsMentorId === 'undefined') {
        localStorage.setItem('mentorId', data?.authenticatedUser?.mentor?.id ?? '');
      }

      setCurrentStudent(student || null);
      setMentorId(
        data?.authenticatedUser?.mentor?.id ?? localStorage.getItem('mentorId') ?? undefined,
      );

      if (
        localStorage.getItem('studentId') ||
        data.authenticatedUser.role === UserRoleType.Mentor
      ) {
        getAllNotifications();
      }
    },
  });

  if (user) {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'ohhixtgv',
      name: `${user?.authenticatedUser?.firstName} ${user?.authenticatedUser?.lastName}`,
      email: user?.authenticatedUser?.email,
    });
  }

  useEffect(() => {
    if (!loading && user?.authenticatedUser.language !== localStorage.getItem('language')) {
      updateUser({
        variables: {
          id: Number.parseInt(user?.authenticatedUser?.id ?? ''),
          data: {
            language: localStorage.getItem('language') ?? 'en',
          },
        },
        onError: () => {
          notify('Failed to update language.', 'error');
        },
      });
    }
  }, [user]);

  const [redeemInvitePasswordSetToken] = useMutation<
    InviteSetPasswordMutationData,
    InviteSetPasswordMutationVars
  >(INVITE_SET_PASSWORD_MUTATION);

  const inviteSetPassword = async (
    email: string,
    token: string,
    password: string,
  ): Promise<{ data: InviteSetPasswordMutationData | null | undefined }> => {
    const { data } = await redeemInvitePasswordSetToken({
      variables: { email, token, password },
    });

    return { data };
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('studentId');
    localStorage.removeItem('questionnaire');
    localStorage.removeItem('mentorId');
    window.Intercom('shutdown');
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        user: user?.authenticatedUser || null,
        refetchUser,
        logout,
        inviteSetPassword,
        isLoading: loading,
        isAuthorized: !!user,
        currentStudent,
        currentStudentId: currentStudent?.id ?? localStorage.getItem('studentId') ?? '',
        mentorId: mentorId ?? localStorage.getItem('mentorId') ?? '',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
