import { useAuth } from '@/app/providers/auth-provider';
import type { AuthContextType } from '@/app/providers/auth-provider/lib/auth-context';
import { UPDATE_USER } from '@/shared/apollo/mutations/user/update-user';
import {
  type LanguageDictionary,
  languagesDic,
  setItemToLocalStorage,
} from '@/shared/constants/global';
import notify from '@/shared/utils/notify';
import { TranslationsLanguage, UserRoleType } from '@/types/types.generated';
import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const useCurrentLang = (): LanguageDictionary => {
  const { user } = useAuth() as AuthContextType;
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const [updateUser] = useMutation(UPDATE_USER);

  const currentLang = useMemo((): LanguageDictionary => {
    const storedLang = localStorage.getItem('language')?.toLowerCase();
    const langParam = searchParams.get('lang')?.toLowerCase() as TranslationsLanguage;

    if (langParam) {
      localStorage.removeItem('language');
      localStorage.removeItem('currency');
    }

    let currentLangValue: string;

    if (
      [TranslationsLanguage.Kr, TranslationsLanguage.Cn, TranslationsLanguage.En].includes(
        langParam,
      )
    ) {
      currentLangValue = langParam;
    } else if (storedLang) {
      currentLangValue = storedLang;
    } else if (location.pathname === '/' || user?.role === UserRoleType.Mentor) {
      currentLangValue = TranslationsLanguage.En;
    } else {
      currentLangValue = TranslationsLanguage.Kr;
    }

    let currentLang = languagesDic.find((lang) => lang.value === currentLangValue);

    if (!currentLang) {
      currentLang =
        languagesDic.find((lang) => lang.value === TranslationsLanguage.En) || languagesDic[0];
    }

    setItemToLocalStorage('language', currentLang.value);

    if (langParam || !storedLang) {
      i18n.changeLanguage(currentLang.value);

      if (user?.id) {
        updateUser({
          variables: {
            id: Number.parseInt(user.id),
            data: {
              language: currentLang.value,
            },
          },
          onError: () => {
            notify('Failed to update language.', 'error');
          },
        });
      }
    }

    return currentLang;
  }, [localStorage.getItem('language')]);

  return currentLang;
};
