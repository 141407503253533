import { useAuth } from '@/app/providers/auth-provider';
import MyDropdownMenu from '@/components/dropdown-menu';
import Button from '@/components/form/button';
import CheckboxField from '@/components/form/checkbox-field';
import { useCurrentLang } from '@/entities/lang-switcher/lib/use-current-lang';
import { UPDATE_USER } from '@/shared/apollo/mutations/user/update-user';
import {
  type LanguageDictionary,
  languageToCurrencyMap,
  languagesDic,
} from '@/shared/constants/global';
import { cn } from '@/shared/utils/functions';
import notify from '@/shared/utils/notify';
import useUpdateCurrency from '@/shared/utils/use-update-currency';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

export const LangSwitcher = ({
  theme = 'default',
}: {
  theme?: 'default' | 'purple';
}) => {
  const [updateUser] = useMutation(UPDATE_USER);
  const { user } = useAuth();

  const currentLang = useCurrentLang();
  const updateCurrency = useUpdateCurrency();

  const [open, setOpen] = useState(false);
  const [t, i18n] = useTranslation('common');

  const onChangeLanguage = async (currentLang: LanguageDictionary) => {
    if (user?.id) {
      await updateUser({
        variables: {
          id: Number.parseInt(user?.id ?? ''),
          data: {
            language: currentLang.value,
          },
        },
        onError: () => {
          notify('Failed to update language.', 'error');
        },
      });
    }
    setOpen(false);
    localStorage.setItem('language', currentLang.value);
    i18n.changeLanguage(currentLang.value);
    const currency = languageToCurrencyMap[currentLang.value];
    updateCurrency(currency);
  };

  return (
    <MyDropdownMenu
      open={open}
      setOpen={setOpen}
      button={
        <Button
          theme="clear"
          className={cn(
            'flex justify-between items-center gap-3 w-full border border-gray-200',
            theme === 'purple' && 'text-color-purple border-none',
          )}
        >
          {currentLang?.label && <span className="grow text-left">{t(currentLang?.label)}</span>}
          {open ? <FaAngleUp /> : <FaAngleDown />}
        </Button>
      }
    >
      <ul className={cn('w-[200px]')}>
        {languagesDic.map((lang) => {
          return (
            <li
              key={lang.value}
              className={cn('border-b border-color-border-grey last:border-b-0')}
            >
              <label
                htmlFor={`lang-${lang.value}`}
                className="flex items-center justify-between gap-3 p-4 cursor-pointer "
              >
                <span
                  className={cn(
                    'text-sm font-medium text-color-dark-purple',
                    theme === 'purple' && 'text-color-purple',
                  )}
                >
                  {t(lang.label)}
                </span>
                <CheckboxField
                  id={`lang-${lang.value}`}
                  onChange={() => onChangeLanguage(lang)}
                  type="radio"
                  name="lang"
                  checked={lang.value === currentLang?.value}
                />
              </label>
            </li>
          );
        })}
      </ul>
    </MyDropdownMenu>
  );
};
